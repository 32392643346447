import {
	Container,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
} from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhishingIcon from "@mui/icons-material/Phishing";
import InboxIcon from "@mui/icons-material/Inbox";
import IntelReport from "../../Organisms/IntelligenceReport/IntelReport";

const actions = [
	{ icon: <VisibilityIcon />, name: "Intel" },
	{ icon: <LocalHospitalIcon />, name: "Injury" },
	{ icon: <PhishingIcon />, name: "Fishing" },
];

const Reports = () => (
	<Container className="Reports" maxWidth="lg">
		<List>
			<ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<InboxIcon />
					</ListItemIcon>
					<ListItemText primary="Inbox" />
				</ListItemButton>
			</ListItem>
		</List>

		<SpeedDial
			ariaLabel="SpeedDial basic example"
			sx={{ position: "absolute", bottom: 16, right: 16 }}
			icon={<SpeedDialIcon />}
		>
			{actions.map((action) => (
				<SpeedDialAction
					key={action.name}
					icon={action.icon}
					tooltipTitle={action.name}
					tooltipOpen
				/>
			))}
		</SpeedDial>

		<IntelReport/>
	</Container>
);
export default Reports;
