import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Components/Pages/Home/Home";
import About from "./Components/Pages/About/About";
import Navigation from "./Components/Molecules/Navigation/Navigation";
import logo from "./logo.svg";
import User from "./Components/Pages/User/User";
import Reports from "./Components/Pages/Reports/Reports";

function App(){
	const [user, setUser] = useState({name:"",token:"",authenticated:false});
	const [openLogin, setOpenLogin] = useState(false);
	
	return (
		<div className="App">
			<Navigation
				logo={logo}
				user={user}
				setUser={setUser}
				openLogin={openLogin}
				setOpenLogin={setOpenLogin}
			/>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="about" element={<About />} />
				{user.authenticated && <Route path="user/:token" element={<User />} />}
				{user.authenticated && <Route path="reports" element={<Reports />} />}
			</Routes>
		</div>
	);
}

export default App;
