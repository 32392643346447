import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import './Navigation.scss'
import { Typography, AppBar, Toolbar, IconButton, Box, Menu, MenuItem, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import Auth from "../../Atoms/Auth/Auth";


// type Anchor = "top" | "left" | "bottom" | "right";

export default function Navigation({logo, user, setUser, openLogin, setOpenLogin}:any) {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

	const handleClickOpenLogin = () => {
		setAnchorElNav(null);
		setOpenLogin(true);
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};
	return (
		<div>
			<AppBar className="Navigation">
				<Toolbar>
					<img src={logo} alt="logo" className="logo" />
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

					<Box sx={{ flexGrow: 0 }}>
						<IconButton onClick={handleOpenNavMenu} sx={{ p: 0 }}>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
						>
							<MenuItem key="Home" onClick={handleCloseNavMenu}>
								<HomeIcon />
								<Link to={"/"}>Home</Link>
							</MenuItem>
							<MenuItem key="About" onClick={handleCloseNavMenu}>
								<InfoIcon />
								<Link to="about">About us</Link>
							</MenuItem>
							<Divider></Divider>
							<MenuItem
								key="Login"
								onClick={
									user.authenticated ? handleCloseNavMenu : handleClickOpenLogin
								}
							>
								<AccountBoxRoundedIcon />
								{user.authenticated ? (
									<Link to={"user/" + user.token}>{user.name}</Link>
								) : (
									<Typography textAlign="center">Login</Typography>
								)}
							</MenuItem>
							{user.authenticated && (
								<MenuItem key="Reports" onClick={handleCloseNavMenu}>
									<InfoIcon />
									<Link to="reports">Reports</Link>
								</MenuItem>
							)}
							<MenuItem>
								<Auth
									user={user}
									setUser={setUser}
									openLogin={openLogin}
									setOpenLogin={setOpenLogin}
								></Auth>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
		</div>
	);
}