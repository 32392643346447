const port = (window.location.port.length > 0) ? ":7071" : window.location.port;
const url = window.location.protocol + '//' + window.location.hostname + port + "/api";
const api = {
	key: "8d198825ad1f47a146eaea872aa4f6ea",
	base: url,
	wetherBase: "/Wether",
};
const requestOptions = {
	method: "GET",
	redirect: "follow"
};

export const GetLatest = async () => {
	try {
		const response = await fetch(api.base + api.wetherBase, requestOptions);
		return await response.json();
	} catch (error) {
		return error;
	}
}