import React from "react";
export const Weather = ({ temp, color, text }:any) => {
	//align text placement based on input
	const tempPosition =(50-((temp.length-1)*10))+'%';
	const textPosition = 100 - (50 + text.length * 6) + "%";
	return(
		<svg width="100%" height="100%" viewBox="0 0 104 104" version="1.1" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
			<rect id={text+"Weather"} x="0" y="0" width="103.566" height="103.048" fill="none"/>
			<path id={text+"Element"} d="M90.806,65.098c14.223,-14.223 14.223,-37.317 0,-51.54c-14.223,-14.223 -37.317,-14.223 -51.54,-0l-25.12,25.119c-14.222,14.223 -14.222,37.318 0,51.541c14.223,14.222 37.318,14.222 51.541,-0l25.119,-25.12Z" fill={color}/>
			<text id={text+"Temp"} x={tempPosition} y="45%" fontFamily="'Roboto', 'Arial', sans-serif" fontSize="24px">{temp}°</text>
			<text id={text+"Text"} x={textPosition} y="80%" fontFamily="'Roboto', 'Arial', sans-serif" fontSize="12px">{text}</text>
		</svg>
	)
}