import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	TextField
} from "@mui/material";
import { Login, Logout } from "../../../Hooks/Auth";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Auth.scss"
import { Link } from "react-router-dom";



const Auth = ({ user, setUser, openLogin, setOpenLogin }: any) => {
	
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [token, setToken] = useState({});

	const usernameChanged = (e: any) => {
		localStorage.setItem("username", e.target.value);
		setUsername(e.target.value);
	};
	const passwordChanged = (e: any) => {
		localStorage.setItem("password", e.target.value);
		setPassword(e.target.value);
	};
	const tokenChanged = (e: any) => {
		localStorage.setItem("token", e);
		setToken(e);
	};

	const handleSuccessfulLogin = (c: any) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		
		setUser({
			name: username,
			token: c.data,
			authenticated: true,
		});
		setOpenLogin(false);
		
	};
	const handleAuth = async () => {
		let user = {
			username: username,
			password: password,
			token: false,
		};
		let credentials = await Login(user);
		setPassword("");
		localStorage.removeItem("password");
		tokenChanged(credentials);

		//if status (ie. 404 etc.), then login has failed
		if (credentials.status) {
			localStorage.removeItem("token");
		} else {
			handleSuccessfulLogin(credentials);
		}
	};

	const handleDeAuth = async () => {
		let credentials:any;
		// if havee seemingly valid token, logout on serverside
		if (Object.hasOwn(token, "data")) {
			credentials = await Logout({ token, setToken });
		}
		setUser({ name: "", token: "", authenticated: false });
		setOpenLogin(false);
		localStorage.setItem("token", credentials);
		localStorage.removeItem("password");
		console.log("Logged out?", user);
	};

	return (
		<React.Fragment>
			{!Object.hasOwn(token, "data") && (
				<Dialog className="login" open={openLogin}>
					<DialogContent className="loginContent">
						<DialogContentText>
							To be able to access all areasof this website, please enter your
							credentials here. If you don't have a login, get in touch. Read
							more at <Link to="about">About us</Link>
						</DialogContentText>
						<br />
						<br />
						<TextField
							id="input-username"
							label="Username"
							variant="standard"
							onChange={usernameChanged}
							value={username}
							className="loginField"
						/>

						<TextField
							id="input-password"
							label="Password"
							variant="standard"
							type="password"
							onChange={passwordChanged}
							value={password}
							className="loginField"
						/>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleDeAuth}>Cancel</Button>
						<Button variant="contained" onClick={handleAuth}>
							Login
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{user.authenticated && (
				<Button
					variant="text"
					aria-label="Log out"
					className="logout"
					onClick={handleDeAuth}
				>
					<LogoutIcon />
					Logout
				</Button>
			)}
		</React.Fragment>
	);
};

export default Auth;