import { Box, Button, Paper, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import './IntelReport.scss';


export default function IntelReport(){
	const [activeStep, setActiveStep] = React.useState(0);
	const [time, setTime] = useState("");
	const [place, setPlace] = useState("");
	const [ammount, setAmmount] = useState("");
	const [type, setType] = useState("");
	const [occupation, setOccupation] = useState("");
	const [identifier, setIdentifier] = useState("");
	const [witness, setWitness] = useState("");
	const [then, setThen] = useState("");
	const handleTime = (e:any) => {
		setTime(e.target.value);
	};
	const handlePlace = (e:any) => {
		setPlace(e.target.value);
	};
	const handleAmmount = (e:any) => {
		setAmmount(e.target.value);
	};
	const handleType = (e:any) => {
		setType(e.target.value);
	};
	const handleOccupation = (e:any) => {
		setOccupation(e.target.value);
	};
	const handleIdentifier = (e:any) => {
		setIdentifier(e.target.value);
	};
	const handleWitness = (e:any) => {
		setWitness(e.target.value);
	};
	const handleThen = (e:any) => {
		setThen(e.target.value);
	};
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};
	const handleSubmit = () => {
		let data = {
			"time":time,
			"place":place,
			"ammount":ammount,
			"type":type,
			"occupation":occupation,
			"identifier":identifier,
			"witness":witness,
			"then":then
		}
		//TODO:Hook up with backend
		console.log(data);
		setTime("");
		setPlace("");
		setAmmount("");
		setType("");
		setOccupation("");
		setIdentifier("");
		setWitness("");
		setThen("");
		setActiveStep(0);
	}
	const steps = [
		{
			label: "Stund",
			description: "Vilken tid inträffase observationen?",
			action: handleTime,
			data:time
		},
		{
			label: "Ställe",
			description: "Var obseverades det?",
			action: handlePlace,
			data:place
		},
		{
			label: "Styrka",
			description: "Antalet",
			action: handleAmmount,
			data:ammount
		},
		{
			label: "Slag",
			description: "Typ av fiende eller objekt",
			action: handleType,
			data:type
		},
		{
			label: "Sysselsättning",
			description: "Fiendes verksamhet",
			action: handleOccupation,
			data:occupation
		},
		{
			label: "Symbol",
			description:"Märkning, siffror, förbandstecken, färg etc. för att identifiera",
			action: handleIdentifier,
			data:identifier
		},
		{
			label: "Sagesman",
			description: "Vem som har gjort observationen",
			action: handleWitness,
			data:witness
		},
		{
			label: "Sedan",
			description: "(Valfri) Vad händer fortsättningsvis",
			action: handleThen,
			data:then
		},
	];
	return (
		<React.Fragment>
			<Stepper
				className="IntelReport"
				activeStep={activeStep}
				orientation="vertical"
			>
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel
							optional={
								index === 7 ? (
									<Typography variant="caption">(Optional)</Typography>
								) : null
							}
						>
							{step.label}
						</StepLabel>
						<StepContent>
							<Paper className="StepContent">
								<TextField
									id={step.label}
									label={step.description}
									variant="standard"
									onChange={step.action}
									value={step.data}
									className="IntelText"
								/>
								<Box sx={{ mb: 2 }}>
									<div>
										<Button
											variant="contained"
											onClick={handleNext}
											sx={{ mt: 1, mr: 1 }}
										>
											{index === steps.length - 1 ? "Finish" : "Next"}
										</Button>
										<Button
											disabled={index === 0}
											onClick={handleBack}
											sx={{ mt: 1, mr: 1 }}
										>
											Back
										</Button>
									</div>
								</Box>
							</Paper>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
						Reset
					</Button>
					<Button onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
						Submimt
					</Button>
				</Paper>
			)}
		</React.Fragment>
	);
}