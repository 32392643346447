import React from 'react';
import { Container } from "@mui/material";
import "./About.scss";
export default function About(params:any) {
	return (
		<Container className="About" maxWidth="md">
			<article>
				<h1>About</h1>
				Ravneberg.co is a website that allows users to easily contact
				individuals at Ravneberg. It also serves as a personal website for
				individuals affiliated with Ravneberg.
				<br />
				<br />
				The website features a contact form where users can easily get intouch
				to specific individuals at Ravneberg. It also includes information about
				us, such as its mission and values, as well as any relevant news or
				updates. Ravneberg.co is also used for personal purposes. Some
				individuals affiliated with Ravneberg may use the website to share
				personal updates or blog posts, and it can serve as a platform for
				developing and to test concepts.
				<br />
				<br />
				Overall, ravneberg.co is a useful and versatile website for both
				professional and personal purposes, providing a convenient way for
				individuals to connect with Ravneberg and stay informed about us.
			</article>
		</Container>
	);
}