import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

const User = () => {
	let { token } = useParams();
	console.log(token)
	return (
		<Container className="User" maxWidth="lg">
			<p>this is your token. valid until 2023-06-15 15:63</p>
			<code>{token}</code>
		</Container>
	);
};
export default User;
