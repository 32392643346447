import React, { useState } from "react";
import { Container, Tooltip } from "@mui/material";
import"./Home.scss";
import { GetLatest } from "../../../Hooks/Wether";
import { Weather } from "../../Atoms/Wether/Weather";

const Home = () => {
	const [waterTemp, setWaterTemp] = useState("-");
	const [airTemp, setAirTemp] = useState("-");

	GetLatest()
		.then((response) => {
			setAirTemp(Number.parseFloat(response[0]["Air temperature [°C]"]).toFixed(0));
			setWaterTemp(Number.parseFloat(response[0]["Sea temperature [°C]"]).toFixed(0));
		})

	return (
		<Container className="Home" maxWidth="lg">
			<Tooltip title="Data-source: http://www.weather.loven.gu.se">
				<section className="sensors">
					<Weather temp={airTemp} color={"#afb42b"} text="Air" />
					<Weather temp={waterTemp} color={"#439889"} text="Water" />
				</section>
			</Tooltip>
		</Container>
	);
};
export default Home;