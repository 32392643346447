const port = (window.location.port.length > 0) ? ":7071" : window.location.port;
const url = window.location.protocol + '//' + window.location.hostname + port+"/api";

const api = {
	key: "8d198825ad1f47a146eaea872aa4f6ea",
	base: url,
	authBase: "/Auth",
};
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");




export const Login = async ({username, password, token}) => {

	var urlencoded = new URLSearchParams();
	if(token){
		urlencoded.append("token", token);
	}
	else if(username && password){
		urlencoded.append("user", username);
		urlencoded.append("password", password);
	}

	const requestOptions = {
		method: "POST",
		body: urlencoded.toString(),
		redirect: "follow"
	};

	try {
		const response = await fetch(api.base+api.authBase, requestOptions);
		const data = await response.json();
		if (response.status === 200) {
			return data;
		}
		else{
			throw response;
		}
	} catch (e) {
		console.info(e);
		return {'status':e.status, 'description':e.statusText};
	}
	
};
export const Logout = async ({ token, setToken }) => {
	if(token){
		var requestOptions = {
			method: 'DELETE',
			redirect: 'follow',
			headers: myHeaders
		};

		fetch(api.base + api.authBase + "/" + token.data, requestOptions)
			.then(response => response.json())
			.then(result => {
				setToken(result);
				return result;
			})
			.catch(error => console.log('error', error));
	}
}